import { gsap, ScrollTrigger } from "gsap/all";
const svgContainer = document.querySelector("#svg-container");
let running = true;
const svg = document.querySelector("#animated-logo");
// function resizeHandler() {
//   // const { width, height } = svgContainer.getBoundingClientRect();
//   // let newHeight = height / 2;
//   svg.setAttribute("width", window.innerWidth);
//   svg.setAttribute("height", window.innerHeight);
// }

if (svgContainer && svg) {
  ScrollTrigger.clearScrollMemory();
  window.history.scrollRestoration = "manual";

  const finalTbar = document.querySelector("#T-bar-f");
  const finalUbar = document.querySelector("#U-bar-f");
  const finalDot = document.querySelector("#Dot-f");

  const shapes = document.querySelectorAll(".animated-logo-shape");
  const intro = document.querySelector(".intro");
  const introSizer = document.querySelector(".intro-sizer");
  const bounds = intro.getBoundingClientRect();

  // 1. move shapes

  shapes.forEach((shape) => {
    let w = shape.getAttribute("width");
    let h = shape.getAttribute("height");
    let x = gsap.utils.random(w, window.innerWidth - w);
    let y = gsap.utils.random(h, window.innerHeight - h);
    let vx = (Math.random() * 2 - 1) * 4;
    let vy = (Math.random() * 2 - 1) * 4;

    // 1.1 move shapes randomly

    const animate = () => {
      x += vx;
      y += vy;

      if (x >= bounds.right) {
        vx = -Math.abs(vx);
        vy = (Math.random() * 2 - 1) * 4;
      }
      if (x <= bounds.left) {
        x = bounds.left + (bounds.left - x);
        vx = Math.abs(vx);
        vy = (Math.random() * 2 - 1) * 4;
      }
      if (y >= bounds.bottom) {
        y = bounds.bottom - (y - bounds.bottom);
        vy = -Math.abs(vy);
        vx = (Math.random() * 2 - 1) * 4;
      }
      if (y <= bounds.top) {
        y = bounds.top + (bounds.top - y);
        vy = Math.abs(vy);
        vx = (Math.random() * 2 - 1) * 4;
      }

      if (shape.matches("rect")) {
        shape.setAttribute("x", x.toFixed(0));
        shape.setAttribute("y", y.toFixed(0));
      } else {
        shape.setAttribute("cx", x.toFixed(0));
        shape.setAttribute("cy", y.toFixed(0));
      }

      if (running) {
        requestAnimationFrame(animate);
      }
    };

    if (running) {
      animate();
    }

    let shapeAttributes;

    if (shape.matches("#T-bar-a")) {
      shapeAttributes = {
        x: finalTbar.getBoundingClientRect().left,
        y: finalTbar.getBoundingClientRect().top,
      };
    }
    if (shape.matches("#U-bar-a")) {
      shapeAttributes = {
        x: finalUbar.getBoundingClientRect().left,
        y: finalUbar.getBoundingClientRect().top,
      };
    }
    if (shape.matches("#DOT-a")) {
      shapeAttributes = {
        cx: finalDot.getBoundingClientRect().left + 21,
        cy: finalDot.getBoundingClientRect().top + 21,
      };
    }

    // 1.2 move shapes to final position

    gsap.to(shape, {
      scrollTrigger: {
        trigger: ".trigger-shapes",
        scrub: 0.1,
        toggleActions: "play pause resume reset",
        onEnter: () => {
          running = false;
        },
        onLeaveBack: () => {
          running = true;
          animate();
        },
      },
      immediateRender: false,
      attr: shapeAttributes,
    });
  });

  // 2. fade in logo

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".trigger-logo",
        toggleActions: "play pause resume reset",
        scrub: 0.1,
      },
    })
    .to("#fixed-logo", {
      opacity: 1,
    })
    .to("#fixed-logo", {
      opacity: 0,
      onComplete: () => {
        intro.style.pointerEvents = "none";
        intro.style.cursor = "default";
      },
      onReverseComplete: () => {
        intro.style.pointerEvents = "all";
        intro.style.cursor = "pointer";
      },
    });

  // 3. fade out logo and shapes

  gsap
    .timeline({
      scrollTrigger: {
        trigger: ".trigger-out",
        toggleActions: "play pause resume reset",
        scrub: 0.1,
      },
    })
    .to(shapes, {
      opacity: 0,
    })
    .fromTo(
      ".site-header",
      {
        opacity: 0,
      },
      { opacity: 1 }
    );

  intro &&
    intro.addEventListener("click", () => {
      document.querySelector("#uti-site").scrollIntoView({
        behavior: "smooth",
      });
      intro.style.pointerEvents = "none";
    });
}
