import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

let mm = gsap.matchMedia();

mm.add("(min-width:1024px)", () => {
  const isOdd = document.querySelectorAll(".featured.is-odd");
  const isEven = document.querySelectorAll(".featured.is-even");
  isOdd &&
    isOdd.forEach((odd) => {
      const xPercentVal = gsap.utils.random(
        gsap.utils.random(-10, -20),
        gsap.utils.random(30, 40)
      );
      const yPercentVal = gsap.utils.random(
        gsap.utils.random(-10, -20),
        gsap.utils.random(30, 40)
      );

      gsap.set(odd, {
        yPercent: yPercentVal,
        xPercent: xPercentVal,
      });
      gsap.to(odd, {
        yPercent: yPercentVal * -1,
        ease: "none",
        scrollTrigger: {
          trigger: ".selection",
          scrub: 1,
        },
      });
    });

  isEven &&
    isEven.forEach((even) => {
      const xPercentVal = gsap.utils.random(
        gsap.utils.random(-10, -20),
        gsap.utils.random(10, 20)
      );
      const yPercentVal = gsap.utils.random(40, 50);

      gsap.set(even, {
        yPercent: 50 + yPercentVal,
        xPercent: xPercentVal,
      });
      gsap.to(even, {
        yPercent: 50 + yPercentVal * -1,
        ease: "none",
        scrollTrigger: {
          trigger: ".selection",
          scrub: 1,
        },
      });
    });
});
