import Swiper, { Mousewheel, FreeMode } from "swiper";
import "swiper/css";

let swiper = new Swiper(".swiper", {
  // slidesPerView: 3,
  slidesPerView: 1,
  freeMode: true,
  modules: [Mousewheel, FreeMode],
  mousewheel: true,
  rewind: true,
  spaceBetween: 0,
  breakpoints: {
    768: {
      slidesPerView: "auto",
      spaceBetween: 30,
      slidesOffsetBefore: window.innerWidth / 3,
      slidesOffsetAfter: 30,
    },
  },
});

swiper.on("click", (swiper, event) => {
  const videoEl = swiper.clickedSlide.querySelector("video");
  if (videoEl) {
    return false;
  }
  if (swiper.clickedIndex === swiper.activeIndex) {
    swiper.slideNext();
  } else {
    swiper.slideTo(swiper.clickedIndex);
  }
});
const slides = document.querySelectorAll(".swiper-slide");
swiper.on("slideChangeTransitionStart", (swiper, event) => {
  slides.forEach((slide) => slide.classList.add("is-changing"));
});
swiper.on("slideChangeTransitionEnd", (swiper, event) => {
  slides.forEach((slide) => slide.classList.remove("is-changing"));
});
