import "./components/block-switch";
import "./components/home-animations";
import "./components/cursor";
import "./components/scroll-to-screens";
import "./components/swiper-projet";
import "./components/toggle-nav";
import "./components/intro";
import "../../node_modules/sortable-tablesort/sortable";

const links = document.querySelectorAll("a");
const site = document.querySelector(".site");

// links.forEach((link) => {
//   link.addEventListener("click", (e) => {
//     e.preventDefault();
//     if (link.href.indexOf("#") != -1) return;

//     site.classList.remove("is-fadein");
//     site.classList.add("is-fadeout");
//     setTimeout(function () {
//       location.href = link.href;
//     }, 500);
//   });
// });

function getOS() {
  var userAgent = window.navigator.userAgent,
    platform =
      window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
    windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
    iosPlatforms = ["iPhone", "iPad", "iPod"],
    os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = "Mac OS";
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = "iOS";
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = "Windows";
  } else if (/Android/.test(userAgent)) {
    os = "Android";
  } else if (/Linux/.test(platform)) {
    os = "Linux";
  }

  return os;
}

if (getOS() === "iOS") {
  setTimeout(function () {
    site.classList.add("is-fadein");
    site.classList.remove("is-fadeout");
  }, 500);
}

document.addEventListener(
  "DOMContentLoaded",
  function () {
    setTimeout(function () {
      site.classList.add("is-fadein");
      site.classList.remove("is-fadeout");
    }, 500);
  },
  false
);
