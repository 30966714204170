import { gsap, ScrollTrigger } from "gsap/all";
gsap.registerPlugin(ScrollTrigger);

const $cursor = document.querySelector("#cursor");
const $goCross = document.querySelectorAll(".featured");

if (
  $cursor &&
  $goCross.length &&
  window.matchMedia &&
  window.matchMedia("(pointer:fine)").matches
) {
  function moveCursor(e) {
    gsap.to($cursor, 0.3, {
      css: {
        left: e.pageX,
        top: e.pageY,
      },
    });
  }

  window.addEventListener("mousemove", moveCursor);

  $cursor &&
    $goCross &&
    $goCross.forEach((el) => {
      el.addEventListener("mouseenter", function (e) {
        $cursor.classList.add("is-shown");
        $cursor.classList.remove("is-hidden");
      });
      el.addEventListener("mouseleave", function (e) {
        $cursor.classList.remove("is-shown");
        $cursor.classList.add("is-hidden");
      });
    });
}
