const switches = document.querySelectorAll(".block-switch");

switches &&
  switches.forEach((s) => {
    const links = s.querySelectorAll("a");

    links &&
      links.forEach((link) => {
        link.addEventListener("click", (e) => {
          e.preventDefault();
          const target = link.dataset.target;
          const href = link.href;

          s.classList.remove("is-architecture");
          s.classList.remove("is-creative");
          s.classList.add("is-active");
          s.classList.add("is-" + target);
          setTimeout(function () {
            document.location.href = href;
          }, 200);
        });
      });
  });
