import { gsap, ScrollTrigger } from "gsap/all";

const $scrollTriggers = document.querySelectorAll(".js-scroll-to");

$scrollTriggers &&
  $scrollTriggers.forEach((trigger) => {
    if (trigger.classList.contains("is-active")) {
      return false;
    }
    const target = document.querySelector(trigger.dataset.target);

    target &&
      trigger.addEventListener("click", (e) => {
        e.preventDefault();
        const activeTriggers = document.querySelectorAll(
          ".js-scroll-to.is-active"
        );
        activeTriggers.forEach((trigger) => {
          trigger.classList.remove("is-active");
        });

        trigger.classList.add("is-active");
        target.scrollIntoView({
          behavior: "smooth",
        });
      });
  });

const menuInfo = document.querySelector(".js-scroll-to.is-infos");
const menuContact = document.querySelector(".js-scroll-to.is-contact");

if (menuInfo && menuContact) {
  gsap.set(".contact", { opacity: 0 });
  gsap.set(".infos", { opacity: 0 });
  gsap.to(".contact", {
    opacity: 1,
    duration: 0.5,
    scrollTrigger: {
      trigger: ".contact",
      start: "top center",
      end: "bottom center",
      onEnter: () => {
        menuContact.classList.add("is-active");
      },
      onLeave: () => {
        menuContact.classList.remove("is-active");
      },
      onEnterBack: () => {
        menuContact.classList.add("is-active");
      },
      onLeaveBack: () => {
        menuContact.classList.remove("is-active");
      },
      toggleActions: "play reverse play reverse",
    },
  });
  gsap.to(".infos", {
    opacity: 1,
    duration: 0.5,
    scrollTrigger: {
      start: "top center",
      end: "bottom center",
      trigger: ".infos",
      onEnter: () => {
        menuInfo.classList.add("is-active");
      },
      onLeave: () => {
        menuInfo.classList.remove("is-active");
      },
      onEnterBack: () => {
        menuInfo.classList.add("is-active");
      },
      onLeaveBack: () => {
        menuInfo.classList.remove("is-active");
      },
      toggleActions: "play reverse play reverse",
    },
  });
}
