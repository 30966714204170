/**
 *
 *
 * OFF NAVIGATION MENU
 *
 *
 */
const nav = document.querySelector(".menu");
const btn = document.querySelector(".hamburger");
const header = document.querySelector(".site-header");

btn &&
  btn.addEventListener("click", function (e) {
    navToggle(e);
  });

function navToggle(e) {
  btn.classList.toggle("open");
  nav.classList.toggle("open");
  header.classList.toggle("open");
}
